export const separatorAndWrapperSigns = [
    "🧡",
    "💓",
    "💔",
    "💕",
    "💖",
    "💗",
    "💘",
    "💙",
    "💚",
    "💛",
    "💜",
    "💝",
    "💞",
    "💟",
    "🖤",
    "🤍",
    "🤎",
    "❤",
    "❥",
    "☙",
    "💩",
    "😀",
    "😁",
    "😂",
    "😃",
    "😄",
    "😅",
    "😆",
    "😇",
    "😉",
    "😊",
    "😋",
    "😌",
    "😍",
    "😎",
    "😏",
    "😐",
    "😑",
    "😒",
    "😓",
    "😔",
    "😕",
    "😖",
    "😗",
    "😘",
    "😙",
    "😚",
    "😛",
    "😜",
    "😝",
    "😞",
    "😟",
    "😠",
    "😡",
    "😢",
    "😣",
    "😤",
    "😥",
    "😦",
    "😧",
    "😨",
    "😩",
    "😪",
    "😫",
    "😬",
    "😭",
    "😮",
    "😯",
    "😰",
    "😱",
    "😲",
    "😳",
    "😴",
    "😵",
    "😶",
    "😷",
    "😈",
    "💀",
    "🤬",
    "🙈",
    "🙉",
    "🙊",
    "😸",
    "✋",
    "🖐",
    "🖖",
    "🤚",
    "☝",
    "👆",
    "👇",
    "👈",
    "👉",
    "🖕",
    "✌",
    "✊",
    "👊",
    "👍",
    "👎",
    "🤛",
    "🤜",
    "👌",
    "🤏",
    "🤘",
    "🤙",
    "🤞",
    "🤟",
    "✍",
    "👏",
    "👋",
    "👐",
    "💅",
    "🤝",
    "🤲",
    "💬",
    "💭",
    "🎅",
    "👹",
    "👻",
    "👽",
    "🤖",
    "🤡",
    "🦹",
    "🧙",
    "🧛",
    "🧝",
    "🧟",
    "👀",
    "👂",
    "👃",
    "👄",
    "👅",
    "👣",
    "💪",
    "🦴",
    "🦵",
    "🦶",
    "🦷",
    "🧠",
    "💎",
    "💍",
    "💊",
    "💉",
    "💈",
    "💄",
    "💅",
    "💺",
    "💻",
    "📁",
    "📂",
    "💾",
    "📀",
    "📆",
    "📈",
    "📉",
    "📊",
    "📋",
    "📌",
    "📍",
    "📎",
    "📏",
    "📖",
    "📣",
    "🔋",
    "📱",
    "📵",
    "🏠",
    "⚾",
    "✈",
    "✉",
    "✆",
    "☎",
    "☏",
    "♂",
    "♀",
    "⚥",
    "⚢",
    "⚣",
    "⚤",
    "⚦",
    "⚧",
    "⚨",
    "⚩",
    "☿",
    "⚲",
    "♁",
    "⚰",
    "⚱",
    "☮",
    "☯",
    "♲",
    "☹",
    "☺",
    "☻",
    "♪",
    "♩",
    "♫",
    "♬",
    "♭",
    "♮",
    "♯",
    "☼",
    "☀",
    "☽",
    "☾",
    "☁",
    "☂",
    "❄",
    "❅",
    "❆",
    "☃",
    "☄",
    "✧",
    "★",
    "✪",
    "✫",
    "☆",
    "✬",
    "✭",
    "❖",
    "❒",
    "☇",
    "☈",
    "☉",
    "☊",
    "☋",
    "☐",
    "☑",
    "☒",
    "☘",
    "✾",
    "✿",
    "❀",
    "✎",
    "✏",
    "✐",
    "✑",
    "✒",
    "✓",
    "✔",
    "✕",
    "✖",
    "☚",
    "☛",
    "☠",
    "☢",
    "☣",
    "⚠",
    "☤",
    "☥",
    "☩",
    "☪",
    "☭",
    "♠",
    "♥",
    "♣",
    "♦",
    "♤",
    "♡",
    "♧",
    "♢",
    "⚀",
    "⚁",
    "⚂",
    "⚃",
    "⚄",
    "⚄",
    "♔",
    "♕",
    "♖",
    "♗",
    "♘",
    "♙",
    "♚",
    "♛",
    "♜",
    "♝",
    "♞",
    "♟",
    "⚇",
    "⚉",
    "✂",
    "✄",
    "⚐",
    "⚑",
    "⚒",
    "⚔",
    "§",
    "℃",
    "℉",
    "№",
    "!",
    "#",
    "$",
    "%",
    "&",
    "'",
    "(",
    ")",
    "∞",
    "*",
    "+",
    "±",
    "×",
    "÷",
    "≠",
    "≤",
    "≥",
    ",",
    "-",
    "–",
    "—",
    ".",
    "/",
    "\\",
    ":",
    ";",
    "<",
    "=",
    ">",
    "?",
    "@",
    "[",
    "]",
    "^",
    "_",
    "`",
    "{",
    "|",
    "}",
    "~",
    "•",
    "○",
    "●",
    "□",
    "■",
    "▲",
    "▼",
    "→",
    "←",
    "↑",
    "↓",
    "↔",
    "↕",
    "√",
    "∫",
    "¿",
    "™",
    "©",
    "®",
    "€",
];