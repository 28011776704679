export const allFonts = [
    {
        name: "Standard"
    },

    /************************************** TEMPLATE **************************************
    {
        name: "xxxxxxxxxxxxxxxxxxxxx",
        characters: {
            "a": "xxxxxxxxxxxxxxxxxxxxx",
            "b": "xxxxxxxxxxxxxxxxxxxxx",
            "c": "xxxxxxxxxxxxxxxxxxxxx",
            "d": "xxxxxxxxxxxxxxxxxxxxx",
            "e": "xxxxxxxxxxxxxxxxxxxxx",
            "f": "xxxxxxxxxxxxxxxxxxxxx",
            "g": "xxxxxxxxxxxxxxxxxxxxx",
            "h": "xxxxxxxxxxxxxxxxxxxxx",
            "i": "xxxxxxxxxxxxxxxxxxxxx",
            "j": "xxxxxxxxxxxxxxxxxxxxx",
            "k": "xxxxxxxxxxxxxxxxxxxxx",
            "l": "xxxxxxxxxxxxxxxxxxxxx",
            "m": "xxxxxxxxxxxxxxxxxxxxx",
            "n": "xxxxxxxxxxxxxxxxxxxxx",
            "o": "xxxxxxxxxxxxxxxxxxxxx",
            "p": "xxxxxxxxxxxxxxxxxxxxx",
            "q": "xxxxxxxxxxxxxxxxxxxxx",
            "r": "xxxxxxxxxxxxxxxxxxxxx",
            "s": "xxxxxxxxxxxxxxxxxxxxx",
            "t": "xxxxxxxxxxxxxxxxxxxxx",
            "u": "xxxxxxxxxxxxxxxxxxxxx",
            "v": "xxxxxxxxxxxxxxxxxxxxx",
            "w": "xxxxxxxxxxxxxxxxxxxxx",
            "x": "xxxxxxxxxxxxxxxxxxxxx",
            "y": "xxxxxxxxxxxxxxxxxxxxx",
            "z": "xxxxxxxxxxxxxxxxxxxxx",

            "A": "xxxxxxxxxxxxxxxxxxxxx",
            "B": "xxxxxxxxxxxxxxxxxxxxx",
            "C": "xxxxxxxxxxxxxxxxxxxxx",
            "D": "xxxxxxxxxxxxxxxxxxxxx",
            "E": "xxxxxxxxxxxxxxxxxxxxx",
            "F": "xxxxxxxxxxxxxxxxxxxxx",
            "G": "xxxxxxxxxxxxxxxxxxxxx",
            "H": "xxxxxxxxxxxxxxxxxxxxx",
            "I": "xxxxxxxxxxxxxxxxxxxxx",
            "J": "xxxxxxxxxxxxxxxxxxxxx",
            "K": "xxxxxxxxxxxxxxxxxxxxx",
            "L": "xxxxxxxxxxxxxxxxxxxxx",
            "M": "xxxxxxxxxxxxxxxxxxxxx",
            "N": "xxxxxxxxxxxxxxxxxxxxx",
            "O": "xxxxxxxxxxxxxxxxxxxxx",
            "P": "xxxxxxxxxxxxxxxxxxxxx",
            "Q": "xxxxxxxxxxxxxxxxxxxxx",
            "R": "xxxxxxxxxxxxxxxxxxxxx",
            "S": "xxxxxxxxxxxxxxxxxxxxx",
            "T": "xxxxxxxxxxxxxxxxxxxxx",
            "U": "xxxxxxxxxxxxxxxxxxxxx",
            "V": "xxxxxxxxxxxxxxxxxxxxx",
            "W": "xxxxxxxxxxxxxxxxxxxxx",
            "X": "xxxxxxxxxxxxxxxxxxxxx",
            "Y": "xxxxxxxxxxxxxxxxxxxxx",
            "Z": "xxxxxxxxxxxxxxxxxxxxx",

            "0": "xxxxxxxxxxxxxxxxxxxxx",
            "1": "xxxxxxxxxxxxxxxxxxxxx",
            "2": "xxxxxxxxxxxxxxxxxxxxx",
            "3": "xxxxxxxxxxxxxxxxxxxxx",
            "4": "xxxxxxxxxxxxxxxxxxxxx",
            "5": "xxxxxxxxxxxxxxxxxxxxx",
            "6": "xxxxxxxxxxxxxxxxxxxxx",
            "7": "xxxxxxxxxxxxxxxxxxxxx",
            "8": "xxxxxxxxxxxxxxxxxxxxx",
            "9": "xxxxxxxxxxxxxxxxxxxxx",

            "?": "xxxxxxxxxxxxxxxxxxxxx",
            "!": "xxxxxxxxxxxxxxxxxxxxx",
            "#": "xxxxxxxxxxxxxxxxxxxxx",
            "£": "xxxxxxxxxxxxxxxxxxxxx",
            "$": "xxxxxxxxxxxxxxxxxxxxx",
            "%": "xxxxxxxxxxxxxxxxxxxxx",
            "&": "xxxxxxxxxxxxxxxxxxxxx",
            "'": "xxxxxxxxxxxxxxxxxxxxx",
            "(": "xxxxxxxxxxxxxxxxxxxxx",
            ")": "xxxxxxxxxxxxxxxxxxxxx",
            "*": "xxxxxxxxxxxxxxxxxxxxx",
            "+": "xxxxxxxxxxxxxxxxxxxxx",
            ",": "xxxxxxxxxxxxxxxxxxxxx",
            "-": "xxxxxxxxxxxxxxxxxxxxx",
            ".": "xxxxxxxxxxxxxxxxxxxxx",
            "/": "xxxxxxxxxxxxxxxxxxxxx",
            ":": "xxxxxxxxxxxxxxxxxxxxx",
            ";": "xxxxxxxxxxxxxxxxxxxxx",
            "<": "xxxxxxxxxxxxxxxxxxxxx",
            "=": "xxxxxxxxxxxxxxxxxxxxx",
            ">": "xxxxxxxxxxxxxxxxxxxxx",
            "@": "xxxxxxxxxxxxxxxxxxxxx",
            "[": "xxxxxxxxxxxxxxxxxxxxx",
            "\\": "xxxxxxxxxxxxxxxxxxxxx",
            "]": "xxxxxxxxxxxxxxxxxxxxx",
            "^": "xxxxxxxxxxxxxxxxxxxxx",
            "_": "xxxxxxxxxxxxxxxxxxxxx",
            "`": "xxxxxxxxxxxxxxxxxxxxx",
            "{": "xxxxxxxxxxxxxxxxxxxxx",
            "|": "xxxxxxxxxxxxxxxxxxxxx",
            "}": "xxxxxxxxxxxxxxxxxxxxx",
            "~": "xxxxxxxxxxxxxxxxxxxxx",   
        }
    },
    ************************************** TEMPLATE **************************************/


    {
        name: "Cyrillic",
        characters: {
            "a": "а",
            "b": "б",
            "c": "c",
            "d": "д",
            "e": "ё",
            "f": "f",
            "g": "g",
            "h": "н",
            "i": "ї",
            "j": "j",
            "k": "к",
            "l": "г",
            "m": "ѫ",
            "n": "п",
            "o": "ѳ",
            "p": "p",
            "q": "ф",
            "r": "я",
            "s": "$",
            "t": "т",
            "u": "ц",
            "v": "ѵ",
            "w": "щ",
            "x": "ж",
            "y": "ч",
            "z": "з",

            "A": "А",
            "B": "Б",
            "C": "C",
            "D": "Д",
            "E": "Є",
            "F": "F",
            "G": "G",
            "H": "H",
            "I": "Ї",
            "J": "J",
            "K": "К",
            "L": "Г",
            "M": "Ѫ",
            "N": "Й",
            "O": "Ѳ",
            "P": "P",
            "Q": "Ф",
            "R": "Я",
            "S": "$",
            "T": "T",
            "U": "Ц",
            "V": "Ѵ",
            "W": "Ш",
            "X": "Ж",
            "Y": "Ч",
            "Z": "З" 
        }
    },
    {
        name: "Ransom Note",
        characters: {
            "a": "𝓪",
            "b": "𝓫",
            "c": "🅒",
            "d": "𝗱",
            "e": "ℯ",
            "f": "𝗳",
            "g": "𝘨",
            "h": "𝙝",
            "i": "𝚒",
            "j": "𝕛",
            "k": "𝘬",
            "l": "𝚕",
            "m": "🅜",
            "n": "𝖓",
            "o": "𝒐",
            "p": "𝘱",
            "q": "𝓺",
            "r": "𝓻",
            "s": "🆂",
            "t": "🆃",
            "u": "𝘂",
            "v": "𝐯",
            "w": "𝔴",
            "x": "ⓧ",
            "y": "🅨",
            "z": "𝕫",

            "A": "𝕬",
            "B": "𝐁",
            "C": "𝑪",
            "D": "𝙳",
            "E": "𝑬",
            "F": "𝓕",
            "G": "𝘎",
            "H": "𝐇",
            "I": "🅘",
            "J": "Ⓙ",
            "K": "𝗞",
            "L": "𝙻",
            "M": "𝑀",
            "N": "ℕ",
            "O": "𝐎",
            "P": "𝑃",
            "Q": "Ⓠ",
            "R": "ℜ",
            "S": "𝓢",
            "T": "𝐓",
            "U": "𝔘",
            "V": "𝐕",
            "W": "🅦",
            "X": "𝔛",
            "Y": "𝚈",
            "Z": "𝑍",

            "0": "𝟘",
            "1": "1",
            "2": "𝟚",
            "3": "3",
            "4": "4",
            "5": "5",
            "6": "6",
            "7": "⑦",
            "8": "8",
            "9": "9"  
        }
    },
    {
        name: "Gothic",
        characters: {
            "a": "𝔞",
            "b": "𝔟",
            "c": "𝔠",
            "d": "𝔡",
            "e": "𝔢",
            "f": "𝔣",
            "g": "𝔤",
            "h": "𝔥",
            "i": "𝔦",
            "j": "𝔧",
            "k": "𝔨",
            "l": "𝔩",
            "m": "𝔪",
            "n": "𝔫",
            "o": "𝔬",
            "p": "𝔭",
            "q": "𝔮",
            "r": "𝔯",
            "s": "𝔰",
            "t": "𝔱",
            "u": "𝔲",
            "v": "𝔳",
            "w": "𝔴",
            "x": "𝔵",
            "y": "𝔶",
            "z": "𝔷",

            "A": "𝔄",
            "B": "𝔅",
            "C": "ℭ",
            "D": "𝔇",
            "E": "𝔈",
            "F": "𝔉",
            "G": "𝔊",
            "H": "ℌ",
            "I": "ℑ",
            "J": "𝔍",
            "K": "𝔎",
            "L": "𝔏",
            "M": "𝔐",
            "N": "𝔑",
            "O": "𝔒",
            "P": "𝔓",
            "Q": "𝔔",
            "R": "ℜ",
            "S": "𝔖",
            "T": "𝔗",
            "U": "𝔘",
            "V": "𝔙",
            "W": "𝔚",
            "X": "𝔛",
            "Y": "𝔜",
            "Z": "ℨ"   
        }
    },
    {
        name: "Light Brackets",
        characters: {
            "a": "〖a〗",
            "b": "〖b〗",
            "c": "〖c〗",
            "d": "〖d〗",
            "e": "〖e〗",
            "f": "〖f〗",
            "g": "〖g〗",
            "h": "〖h〗",
            "i": "〖i〗",
            "j": "〖j〗",
            "k": "〖k〗",
            "l": "〖l〗",
            "m": "〖m〗",
            "n": "〖n〗",
            "o": "〖o〗",
            "p": "〖p〗",
            "q": "〖q〗",
            "r": "〖r〗",
            "s": "〖s〗",
            "t": "〖t〗",
            "u": "〖u〗",
            "v": "〖v〗",
            "w": "〖w〗",
            "x": "〖x〗",
            "y": "〖y〗",
            "z": "〖z〗",

            "A": "〖A〗",
            "B": "〖B〗",
            "C": "〖C〗",
            "D": "〖D〗",
            "E": "〖E〗",
            "F": "〖F〗",
            "G": "〖G〗",
            "H": "〖H〗",
            "I": "〖I〗",
            "J": "〖J〗",
            "K": "〖K〗",
            "L": "〖L〗",
            "M": "〖M〗",
            "N": "〖N〗",
            "O": "〖O〗",
            "P": "〖P〗",
            "Q": "〖Q〗",
            "R": "〖R〗",
            "S": "〖S〗",
            "T": "〖T〗",
            "U": "〖U〗",
            "V": "〖V〗",
            "W": "〖W〗",
            "X": "〖X〗",
            "Y": "〖Y〗",
            "Z": "〖Z〗",

            "0": "〖0〗",
            "1": "〖1〗",
            "2": "〖2〗",
            "3": "〖3〗",
            "4": "〖4〗",
            "5": "〖5〗",
            "6": "〖6〗",
            "7": "〖7〗",
            "8": "〖8〗",
            "9": "〖9〗",  
        }
    },
    {
        name: "Dark Brackets",
        characters: {
            "a": "【a】",
            "b": "【b】",
            "c": "【c】",
            "d": "【d】",
            "e": "【e】",
            "f": "【f】",
            "g": "【g】",
            "h": "【h】",
            "i": "【i】",
            "j": "【j】",
            "k": "【k】",
            "l": "【l】",
            "m": "【m】",
            "n": "【n】",
            "o": "【o】",
            "p": "【p】",
            "q": "【q】",
            "r": "【r】",
            "s": "【s】",
            "t": "【t】",
            "u": "【u】",
            "v": "【v】",
            "w": "【w】",
            "x": "【x】",
            "y": "【y】",
            "z": "【z】",

            "A": "【A】",
            "B": "【B】",
            "C": "【C】",
            "D": "【D】",
            "E": "【E】",
            "F": "【F】",
            "G": "【G】",
            "H": "【H】",
            "I": "【I】",
            "J": "【J】",
            "K": "【K】",
            "L": "【L】",
            "M": "【M】",
            "N": "【N】",
            "O": "【O】",
            "P": "【P】",
            "Q": "【Q】",
            "R": "【R】",
            "S": "【S】",
            "T": "【T】",
            "U": "【U】",
            "V": "【V】",
            "W": "【W】",
            "X": "【X】",
            "Y": "【Y】",
            "Z": "【Z】",

            "0": "【0】",
            "1": "【1】",
            "2": "【2】",
            "3": "【3】",
            "4": "【4】",
            "5": "【5】",
            "6": "【6】",
            "7": "【7】",
            "8": "【8】",
            "9": "【9】"
        }
    },
    {
        name: "Handwriting",
        characters: {
            "a": "𝒶",
            "b": "𝒷",
            "c": "𝒸",
            "d": "𝒹",
            "e": "𝑒",
            "f": "𝒻",
            "g": "𝑔",
            "h": "𝒽",
            "i": "𝒾",
            "j": "𝒿",
            "k": "𝓀",
            "l": "𝓁",
            "m": "𝓂",
            "n": "𝓃",
            "o": "𝑜",
            "p": "𝓅",
            "q": "𝓆",
            "r": "𝓇",
            "s": "𝓈",
            "t": "𝓉",
            "u": "𝓊",
            "v": "𝓋",
            "w": "𝓌",
            "x": "𝓍",
            "y": "𝓎",
            "z": "𝓏",

            "A": "𝒜",
            "B": "𝐵",
            "C": "𝒞",
            "D": "𝒟",
            "E": "𝐸",
            "F": "𝐹",
            "G": "𝒢",
            "H": "𝐻",
            "I": "𝐼",
            "J": "𝒥",
            "K": "𝒦",
            "L": "𝐿",
            "M": "𝑀",
            "N": "𝒩",
            "O": "𝒪",
            "P": "𝒫",
            "Q": "𝒬",
            "R": "𝑅",
            "S": "𝒮",
            "T": "𝒯",
            "U": "𝒰",
            "V": "𝒱",
            "W": "𝒲",
            "X": "𝒳",
            "Y": "𝒴",
            "Z": "𝒵"  
        }
    },
    {
        name: "Ancient Style 2",
        characters: {
            "a": "ꋬ",
            "b": "ꃳ",
            "c": "ꉔ",
            "d": "꒯",
            "e": "ꏂ",
            "f": "ꊰ",
            "g": "ꍌ",
            "h": "ꁝ",
            "i": "꒐",
            "j": "꒻",
            "k": "ꀘ",
            "l": "꒒",
            "m": "ꂵ",
            "n": "ꋊ",
            "o": "ꄲ",
            "p": "ꉣ",
            "q": "ꆰ",
            "r": "ꋪ",
            "s": "ꇙ",
            "t": "꓄",
            "u": "꒤",
            "v": "꒦",
            "w": "ꅐ",
            "x": "ꉧ",
            "y": "ꌦ",
            "z": "ꁴ",

            "A": "ꋬ",
            "B": "ꃳ",
            "C": "ꉔ",
            "D": "꒯",
            "E": "ꏂ",
            "F": "ꊰ",
            "G": "ꍌ",
            "H": "ꁝ",
            "I": "꒐",
            "J": "꒻",
            "K": "ꀘ",
            "L": "꒒",
            "M": "ꂵ",
            "N": "ꋊ",
            "O": "ꄲ",
            "P": "ꉣ",
            "Q": "ꆰ",
            "R": "ꋪ",
            "S": "ꇙ",
            "T": "꓄",
            "U": "꒤",
            "V": "꒦",
            "W": "ꅐ",
            "X": "ꉧ",
            "Y": "ꌦ",
            "Z": "ꁴ"  
        }
    },
    {
        name: "Ancient Style 1",
        characters: {
            "a": "ꍏ",
            "b": "ꌃ",
            "c": "ꉓ",
            "d": "ꀸ",
            "e": "ꍟ",
            "f": "ꎇ",
            "g": "ꁅ",
            "h": "ꃅ",
            "i": "ꀤ",
            "j": "ꀭ",
            "k": "ꀘ",
            "l": "꒒",
            "m": "ꂵ",
            "n": "ꈤ",
            "o": "ꂦ",
            "p": "ꉣ",
            "q": "ꆰ",
            "r": "ꋪ",
            "s": "ꌗ",
            "t": "꓄",
            "u": "ꀎ",
            "v": "ꃴ",
            "w": "ꅏ",
            "x": "ꊼ",
            "y": "ꌩ",
            "z": "ꁴ",

            "A": "ꍏ",
            "B": "ꌃ",
            "C": "ꉓ",
            "D": "ꀸ",
            "E": "ꍟ",
            "F": "ꎇ",
            "G": "ꁅ",
            "H": "ꃅ",
            "I": "ꀤ",
            "J": "ꀭ",
            "K": "ꀘ",
            "L": "꒒",
            "M": "ꂵ",
            "N": "ꈤ",
            "O": "ꂦ",
            "P": "ꉣ",
            "Q": "ꆰ",
            "R": "ꋪ",
            "S": "ꌗ",
            "T": "꓄",
            "U": "ꀎ",
            "V": "ꃴ",
            "W": "ꅏ",
            "X": "ꊼ",
            "Y": "ꌩ",
            "Z": "ꁴ",  
        }
    },
    {
        name: "Japanese",
        characters: {
            "a": "卂",
            "b": "乃",
            "c": "匚",
            "d": "ᗪ",
            "e": "乇",
            "f": "千",
            "g": "Ꮆ",
            "h": "卄",
            "i": "丨",
            "j": "ﾌ",
            "k": "Ҝ",
            "l": "ㄥ",
            "m": "爪",
            "n": "几",
            "o": "ㄖ",
            "p": "卩",
            "q": "Ɋ",
            "r": "尺",
            "s": "丂",
            "t": "ㄒ",
            "u": "ㄩ",
            "v": "ᐯ",
            "w": "山",
            "x": "乂",
            "y": "ㄚ",
            "z": "乙",

            "A": "卂",
            "B": "乃",
            "C": "匚",
            "D": "ᗪ",
            "E": "乇",
            "F": "千",
            "G": "Ꮆ",
            "H": "卄",
            "I": "丨",
            "J": "ﾌ",
            "K": "Ҝ",
            "L": "ㄥ",
            "M": "爪",
            "N": "几",
            "O": "ㄖ",
            "P": "卩",
            "Q": "Ɋ",
            "R": "尺",
            "S": "丂",
            "T": "ㄒ",
            "U": "ㄩ",
            "V": "ᐯ",
            "W": "山",
            "X": "乂",
            "Y": "ㄚ",
            "Z": "乙"  
        }
    },
    {
        name: "Light Squares",
        characters: {
            "a": "🄰",
            "b": "🄱",
            "c": "🄲",
            "d": "🄳",
            "e": "🄴",
            "f": "🄵",
            "g": "🄶",
            "h": "🄷",
            "i": "🄸",
            "j": "🄹",
            "k": "🄺",
            "l": "🄻",
            "m": "🄼",
            "n": "🄽",
            "o": "🄾",
            "p": "🄿",
            "q": "🅀",
            "r": "🅁",
            "s": "🅂",
            "t": "🅃",
            "u": "🅄",
            "v": "🅅",
            "w": "🅆",
            "x": "🅇",
            "y": "🅈",
            "z": "🅉",

            "A": "🄰",
            "B": "🄱",
            "C": "🄲",
            "D": "🄳",
            "E": "🄴",
            "F": "🄵",
            "G": "🄶",
            "H": "🄷",
            "I": "🄸",
            "J": "🄹",
            "K": "🄺",
            "L": "🄻",
            "M": "🄼",
            "N": "🄽",
            "O": "🄾",
            "P": "🄿",
            "Q": "🅀",
            "R": "🅁",
            "S": "🅂",
            "T": "🅃",
            "U": "🅄",
            "V": "🅅",
            "W": "🅆",
            "X": "🅇",
            "Y": "🅈",
            "Z": "🅉"  
        }
    },
    {
        name: "Mirrored",
        characters: {
            "a": "ɒ",
            "b": "d",
            "c": "ɔ",
            "d": "b",
            "e": "ɘ",
            "f": "ʇ",
            "g": "ϱ",
            "h": "ʜ",
            "i": "i",
            "j": "į",
            "k": "ʞ",
            "l": "l",
            "m": "m",
            "n": "n",
            "o": "o",
            "p": "q",
            "q": "p",
            "r": "ɿ",
            "s": "ƨ",
            "t": "Ɉ",
            "u": "υ",
            "v": "v",
            "w": "w",
            "x": "x",
            "y": "γ",
            "z": "z",

            "A": "A",
            "B": "ᙠ",
            "C": "Ɔ",
            "D": "ᗡ",
            "E": "Ǝ",
            "F": "ꟻ",
            "G": "Ꭾ",
            "H": "H",
            "I": "I",
            "J": "Ⴑ",
            "K": "ﻼ",
            "L": "⅃",
            "M": "M",
            "N": "И",
            "O": "O",
            "P": "ꟼ",
            "Q": "Ϙ",
            "R": "Я",
            "S": "Ƨ",
            "T": "T",
            "U": "U",
            "V": "V",
            "W": "W",
            "X": "X",
            "Y": "Y",
            "Z": "Z",

            "0": "0",
            "1": "|",
            "2": "ς",
            "3": "Ɛ",
            "4": "μ",
            "5": "ट",
            "6": "მ",
            "7": "٢",
            "8": "8",
            "9": "୧",
            "?": "⸮",   
        }
    },
    {
        name: "Mirrored Upside Down",
        characters: {
            "a": "ɐ",
            "b": "q",
            "c": "ɔ",
            "d": "p",
            "e": "ǝ",
            "f": "ɟ",
            "g": "ƃ",
            "h": "ɥ",
            "i": "ᴉ",
            "j": "ɾ",
            "k": "ʞ",
            "l": "ʅ",
            "m": "ɯ",
            "n": "u",
            "o": "o",
            "p": "d",
            "q": "b",
            "r": "ɹ",
            "s": "s",
            "t": "ʇ",
            "u": "n",
            "v": "ʌ",
            "w": "ʍ",
            "x": "x",
            "y": "ʎ",
            "z": "z",

            "A": "∀",
            "B": "ꓭ",
            "C": "Ͻ",
            "D": "ᗡ",
            "E": "Ǝ",
            "F": "ᖵ",
            "G": "⅁",
            "H": "H",
            "I": "I",
            "J": "ᒋ",
            "K": "ꓘ",
            "L": "⅂",
            "M": "ꟽ",
            "N": "N",
            "O": "O",
            "P": "Ԁ",
            "Q": "Ꝺ",
            "R": "ꓤ",
            "S": "S",
            "T": "ꓕ",
            "U": "Ո",
            "V": "Ʌ",
            "W": "Ϻ",
            "X": "X",
            "Y": "⅄",
            "Z": "Z",

            "0": "0",
            "1": "Ɩ",
            "2": "ᄅ",
            "3": "Ɛ",
            "4": "ㄣ",
            "5": "ϛ",
            "6": "9",
            "7": "ㄥ",
            "8": "8",
            "9": "6",
            "!": "¡",
            "?": "¿",
            "&": "⅋"
        }
    },
    {
        name: "Dark Bubbles",
        characters: {
            "a": "🅐",
            "b": "🅑",
            "c": "🅒",
            "d": "🅓",
            "e": "🅔",
            "f": "🅕",
            "g": "🅖",
            "h": "🅗",
            "i": "🅘",
            "j": "🅙",
            "k": "🅚",
            "l": "🅛",
            "m": "🅜",
            "n": "🅝",
            "o": "🅞",
            "p": "🅟",
            "q": "🅠",
            "r": "🅡",
            "s": "🅢",
            "t": "🅣",
            "u": "🅤",
            "v": "🅥",
            "w": "🅦",
            "x": "🅧",
            "y": "🅨",
            "z": "🅩",

            "A": "🅐",
            "B": "🅑",
            "C": "🅒",
            "D": "🅓",
            "E": "🅔",
            "F": "🅕",
            "G": "🅖",
            "H": "🅗",
            "I": "🅘",
            "J": "🅙",
            "K": "🅚",
            "L": "🅛",
            "M": "🅜",
            "N": "🅝",
            "O": "🅞",
            "P": "🅟",
            "Q": "🅠",
            "R": "🅡",
            "S": "🅢",
            "T": "🅣",
            "U": "🅤",
            "V": "🅥",
            "W": "🅦",
            "X": "🅧",
            "Y": "🅨",
            "Z": "🅩",

            "0": "⓿",
            "1": "➊",
            "2": "➋",
            "3": "➌",
            "4": "➍",
            "5": "➎",
            "6": "➏",
            "7": "➐",
            "8": "➑",
            "9": "➒",  
        }
    },
    {
        name: "Light Bubbles",
        characters: {
            "a": "ⓐ",
            "b": "ⓑ",
            "c": "ⓒ",
            "d": "ⓓ",
            "e": "ⓔ",
            "f": "ⓕ",
            "g": "ⓖ",
            "h": "ⓗ",
            "i": "ⓘ",
            "j": "ⓙ",
            "k": "ⓚ",
            "l": "ⓛ",
            "m": "ⓜ",
            "n": "ⓝ",
            "o": "ⓞ",
            "p": "ⓟ",
            "q": "ⓠ",
            "r": "ⓡ",
            "s": "ⓢ",
            "t": "ⓣ",
            "u": "ⓤ",
            "v": "ⓥ",
            "w": "ⓦ",
            "x": "ⓧ",
            "y": "ⓨ",
            "z": "ⓩ",

            "A": "Ⓐ",
            "B": "Ⓑ",
            "C": "Ⓒ",
            "D": "Ⓓ",
            "E": "Ⓔ",
            "F": "Ⓕ",
            "G": "Ⓖ",
            "H": "Ⓗ",
            "I": "Ⓘ",
            "J": "Ⓙ",
            "K": "Ⓚ",
            "L": "Ⓛ",
            "M": "Ⓜ",
            "N": "Ⓝ",
            "O": "Ⓞ",
            "P": "Ⓟ",
            "Q": "Ⓠ",
            "R": "Ⓡ",
            "S": "Ⓢ",
            "T": "Ⓣ",
            "U": "Ⓤ",
            "V": "Ⓥ",
            "W": "Ⓦ",
            "X": "Ⓧ",
            "Y": "Ⓨ",
            "Z": "Ⓩ",

            "0": "⓪",
            "1": "①",
            "2": "②",
            "3": "③",
            "4": "④",
            "5": "⑤",
            "6": "⑥",
            "7": "⑦",
            "8": "⑧",
            "9": "⑨"
        }
    },
    {
        name: "Underline",
        characters: {
            "a": "a̲",
            "b": "b̲",
            "c": "c̲",
            "d": "d̲",
            "e": "e̲",
            "f": "f̲",
            "g": "g̲",
            "h": "h̲",
            "i": "i̲",
            "j": "j̲",
            "k": "k̲",
            "l": "l̲",
            "m": "m̲",
            "n": "n̲",
            "o": "o̲",
            "p": "p̲",
            "q": "q̲",
            "r": "r̲",
            "s": "s̲",
            "t": "t̲",
            "u": "u̲",
            "v": "v̲",
            "w": "w̲",
            "x": "x̲",
            "y": "y̲",
            "z": "z̲",

            "A": "A̲",
            "B": "B̲",
            "C": "C̲",
            "D": "D̲",
            "E": "E̲",
            "F": "F̲",
            "G": "G̲",
            "H": "H̲",
            "I": "I̲",
            "J": "J̲",
            "K": "K̲",
            "L": "L̲",
            "M": "M̲",
            "N": "N̲",
            "O": "O̲",
            "P": "P̲",
            "Q": "Q̲",
            "R": "R̲",
            "S": "S̲",
            "T": "T̲",
            "U": "U̲",
            "V": "V̲",
            "W": "W̲",
            "X": "X̲",
            "Y": "Y̲",
            "Z": "Z̲",

            "0": "0̲",
            "1": "1̲",
            "2": "2̲",
            "3": "3̲",
            "4": "4̲",
            "5": "5̲",
            "6": "6̲",
            "7": "7̲",
            "8": "8̲",
            "9": "9̲",

            "?": "?̲",
            "!": "!̲",
            "#": "#̲",
            "£": "£̲",
            "$": "$̲",
            "%": "%̲ ",
            "&": "&̲",
            "\"": "\"̲",
            "'": "'̲",
            "(": "(̲",
            ")": ")̲",
            "*": "*̲",
            "+": "+̲",
            ",": ",̲",
            "-": "-̲",
            ".": ".̲",
            "/": "/̲",
            ":": ":̲",
            ";": ";̲",
            "<": "<̲",
            "=": "=̲",
            ">": ">̲",
            "@": "@̲",
            "[": "[̲",
            "\\": "\\̲",
            "]": "]̲",
            "^": "^̲",
            "_": "_̲",
            "`": "`̲",
            "{": "{̲",
            "|": "|̲",
            "}": "}̲",
            "~": "~̲",   
        }
    },
    {
        name: "Strikethrough",
        characters: {
            "a": "a̶",
            "b": "b̶",
            "c": "c̶",
            "d": "d̶",
            "e": "e̶",
            "f": "f̶",
            "g": "g̶",
            "h": "h̶",
            "i": "i̶",
            "j": "j̶",
            "k": "k̶",
            "l": "l̶",
            "m": "m̶",
            "n": "n̶",
            "o": "o̶",
            "p": "p̶",
            "q": "q̶",
            "r": "r̶",
            "s": "s̶",
            "t": "t̶",
            "u": "u̶",
            "v": "v̶",
            "w": "w̶",
            "x": "x̶",
            "y": "y̶",
            "z": "z̶",

            "A": "A̶",
            "B": "B̶",
            "C": "C̶",
            "D": "D̶",
            "E": "E̶",
            "F": "F̶",
            "G": "G̶",
            "H": "H̶",
            "I": "I̶",
            "J": "J̶",
            "K": "K̶",
            "L": "L̶",
            "M": "M̶",
            "N": "N̶",
            "O": "O̶",
            "P": "P̶",
            "Q": "Q̶",
            "R": "R̶",
            "S": "S̶",
            "T": "T̶",
            "U": "U̶",
            "V": "V̶",
            "W": "W̶",
            "X": "X̶",
            "Y": "Y̶",
            "Z": "Z̶",

            "0": "0̶",
            "1": "1̶",
            "2": "2̶",
            "3": "3̶",
            "4": "4̶",
            "5": "5̶",
            "6": "6̶",
            "7": "7̶",
            "8": "8̶",
            "9": "9̶",

            "?": "?̶",
            "!": "!̶",
            "#": "#̶",
            "£": "£̶",
            "$": "$̶",
            "&": "&̶",
            "%": "%̶",
            "(": "(̶",
            ")": ")̶",
            "*": "*̶",
            "+": "+̶",
            "-": "-̶",
            ":": ":̶",
            ";": ";̶",
            "=": "=̶",
            "@": "@̶",
            "[": "[̶ ",
            "]": "]̶",
            "\\": "\\̶",
            "/": "/̶",
            "^": "^̶",
            "_": "_̶",
            "`": "`̶",
            "{": "{̶",
            "}": "}̶",
            "|": "|̶",
            "~": "~̶",
            "<": "<̶",
            ">": ">̶",
            ",": ",̶",
            ".": ".̶"
        }
    },
    {
        name: "Braille",
        characters: {
            "a": "⠁",
            "b": "⠃",
            "c": "⠉",
            "d": "⠙",
            "e": "⠑",
            "f": "⠋",
            "g": "⠛",
            "h": "⠓",
            "i": "⠊",
            "j": "⠚",
            "k": "⠅",
            "l": "⠇",
            "m": "⠍",
            "n": "⠝",
            "o": "⠕",
            "p": "⠏",
            "q": "⠟",
            "r": "⠗",
            "s": "⠎",
            "t": "⠞",
            "u": "⠥",
            "v": "⠧",
            "w": "⠺",
            "x": "⠭",
            "y": "⠽",
            "z": "⠵",

            "A": "⠁⠃",
            "B": "⠁⠉",
            "C": "⠁⠙",
            "D": "⠁⠑",
            "E": "⠁⠋",
            "F": "⠁⠛",
            "G": "⠁⠓",
            "H": "⠁⠊",
            "I": "⠑⠊",
            "J": "⠊⠚",
            "K": "⠅⠓",
            "L": "⠇⠕",
            "M": "⠍⠝",
            "N": "⠝⠕",
            "O": "⠕⠕",
            "P": "⠏⠇",
            "Q": "⠟⠕",
            "R": "⠗⠑",
            "S": "⠎⠎",
            "T": "⠞⠞",
            "U": "⠥⠕",
            "V": "⠧⠕",
            "W": "⠺⠕",
            "X": "⠭⠕",
            "Y": "⠽⠕",
            "Z": "⠵⠕",

            ".": "⠼⠚",
            ",": "⠼⠲",
            " ": " "
          }
    },
    {
        name: "Morse Code",
        characters: {
            "a": "•-",
            "b": "-•••",
            "c": "-•-•",
            "d": "-••",
            "e": "•",
            "f": "••-•",
            "g": "--•",
            "h": "••••",
            "i": "••",
            "j": "•---",
            "k": "-•-",
            "l": "•-••",
            "m": "--",
            "n": "-•",
            "o": "---",
            "p": "•--•",
            "q": "--•-",
            "r": "•-•",
            "s": "•••",
            "t": "-",
            "u": "••-",
            "v": "•••-",
            "w": "•--",
            "x": "-••-",
            "y": "-•--",
            "z": "--••",

            "A": "•-",
            "B": "-•••",
            "C": "-•-•",
            "D": "-••",
            "E": "•",
            "F": "••-•",
            "G": "--•",
            "H": "••••",
            "I": "••",
            "J": "•---",
            "K": "-•-",
            "L": "•-••",
            "M": "--",
            "N": "-•",
            "O": "---",
            "P": "•--•",
            "Q": "--•-",
            "R": "•-•",
            "S": "•••",
            "T": "-",
            "U": "••-",
            "V": "•••-",
            "W": "•--",
            "X": "-••-",
            "Y": "-•--",
            "Z": "--••",

            "0": "-----",
            "1": "•----",
            "2": "••---",
            "3": "•••--",
            "4": "••••-",
            "5": "•••••",
            "6": "-••••",
            "7": "--•••",
            "8": "---••",
            "9": "----•",

            ".": "•-•-•-",
            ",": "--••--",
            "?": "••--••",
            "'": "•----•",
            "!": "-•-•--",
            "/": "-••-•",
            "(": "-•--•",
            ")": "-•--•-",
            "&": "•-•••",
            ":": "---•••",
            ";": "-•-•-•",
            "=": "-•••-",
            "+": "•-•-•",
            "-": "-••••-",
            "_": "••--•-",
            "\"": "•-••-•",
            "$": "•••-••-",
            "@": "•--•-•",
            " ": " "
        }
    },
];